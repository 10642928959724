import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { VerificationStep, getVerificationStep, verificationSteps } from 'components/Verification/verificationSteps';
import { ReactComponent as CheckIcon } from 'images/green-check.svg';

import useVerificationSteps from 'components/Verification/useVerificationSteps';
import { FlowComponentType } from 'routes/FlowRouter';
import { VerificationResult } from 'enums/FlowNextResults';

import styles from './VerificationNavigation.module.scss';

interface VerificationNavigationState {
  step: VerificationStep;
}

export interface VerificationStepComponent {
  handleNext(result?: any): void;
}

const VerificationNavigation = ({ handleNext }: FlowComponentType) => {
  const location = useLocation();
  const state = location.state as VerificationNavigationState;

  const {
    currentStep,
    setCurrentStep,
    handleNextStep,
    firstUncompletedStep,
    verificationStepsStatus,
    verificationStarted,
    verificationCompleted,
  } = useVerificationSteps(state?.step);

  useEffect(() => {
    if (verificationCompleted) {
      handleNext(VerificationResult.Completed);
    }
  }, [verificationCompleted]);

  useEffect(() => {
    if (verificationStarted) {
      setCurrentStep(firstUncompletedStep);
    }
  }, []);

  return (
    <>
      <div className={styles.navigation}>
        {verificationSteps.map((step) => (
          <div
            onClick={() => setCurrentStep(step.id)}
            key={step.id}
            className={clsx(styles.step, {
              [styles.active]: step.id === currentStep,
            })}
          >
            <step.icon
              className={clsx(styles.icon, {
                [styles.completed]: verificationStepsStatus[step.id],
              })}
            />
            {verificationStepsStatus[step.id] && <CheckIcon className={styles.check} />}
          </div>
        ))}
      </div>
      <div className={styles.stepContent}>
        {React.createElement(getVerificationStep(currentStep).component, {
          handleNext: handleNextStep,
        })}
      </div>
    </>
  );
};

export default VerificationNavigation;
