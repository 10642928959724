import React, { Suspense, useState } from 'react';
import Button from 'components/Button';
import DropzoneWidget, { useFiles } from 'components/DropzoneWidget';
import { DROPBOX_EXTENSIONS } from 'components/Verification/Steps/UploadPaystubs/UploadPaystubs';
import FormContainer from 'components/LoanForm/FormContainer';
import ReactLoading from 'react-loading';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { useLocation } from 'react-router-dom';
import { updateApplicationStatus, uploadDocuments } from 'thunks';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { ManualDeductionMethod } from 'components/SetupDeduction/ManualDeduction/ManualDeduction';
import { FlowComponentType } from 'routes/FlowRouter';
import { DigifiDocumentType } from 'api/DocumentsApi';
import FormNavigation from 'components/FormNavigation';

import styles from './VerifyPayrollDeduction.module.scss';

const FilesPreview = React.lazy(() => import('components/FilesPreview'));

interface LocationState {
  state: {
    flow: ManualDeductionMethod;
  };
}

const subtitle: Record<ManualDeductionMethod, string> = {
  viaHr: 'Please upload a screenshot of the confirmation you received from your HR manager.',
  viaPayroll: 'Please upload a screenshot of your payroll system confirming the deduction has been set up.',
};

const VerifyPayrollDeduction = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const location = useLocation();

  const { state } = location as LocationState;

  const { id: applicationId } = useSelector(getApplicationData).application!;
  const { handleAddFiles, files, handleRemoveFiles } = useFiles();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleContinue = async () => {
    const filesForUpload: File[] = files.map((item) => item.file);
    setIsLoading(true);
    await dispatchWithUnwrap(
      uploadDocuments({ applicationId, files: filesForUpload, fileType: DigifiDocumentType.Other }),
    );

    await dispatchWithUnwrap(
      updateApplicationStatus({ applicationId, applicationStatus: ApplicationStatusName.FinalReview }),
    );
    setIsLoading(false);

    handleNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Verify Payroll Deduction" subtitle={subtitle[state?.flow ?? ManualDeductionMethod.ViaHr]}>
        <div className={styles.fileUploadContainer}>
          <div className={styles.dropzoneContainer}>
            <DropzoneWidget handleFiles={handleAddFiles} extensions={DROPBOX_EXTENSIONS} />
          </div>

          {Boolean(files.length) && (
            <Suspense fallback={<ReactLoading type="spin" color="#fff" />}>
              <FilesPreview files={files} handleRemove={handleRemoveFiles} />
            </Suspense>
          )}
        </div>

        <Button className={styles.button} disabled={!files.length} onClick={handleContinue} isLoading={isLoading}>
          Confirm
        </Button>
      </FormContainer>
    </>
  );
};

export default VerifyPayrollDeduction;
